import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';


//Look at diferences between infowindow content vs https://dev.to/rihdusr/render-a-react-component-in-google-map-171o
const useStyles = makeStyles((theme) => ({
    root: {
        width: '250px',
    },
    headerRoot: {
        width: '100%',
        color: '#424242',
        fontSize: '10pt'
    },
    headerLine1: {
        fontWeight: "bold"
    },
    contentRoot: {
        width: '100%',
    },
    listItem: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.03)'
    },
    hasCoverage: {
        color: '#777777'
    },
    noCoverage: {
        color: '#777777'
    }
}));

function Header(props) {
    const classes = useStyles();
    return (
        <div className={classes.headerRoot}>
            {props.children ? props.children :
                <>
                    {
                        (props.place && props.place.address_components) &&
                        <>
                            <Typography className={classes.headerLine1}>
                                {props.place.address_components[0] &&
                                    props.place.address_components[0].long_name + " "
                                }
                                {props.place.address_components[1] &&
                                    props.place.address_components[1].long_name
                                }
                            </Typography>
                            <Typography className={classes.headerLine2}>
                                {props.place.address_components[2] &&
                                    props.place.address_components[2].long_name
                                }
                            </Typography>
                            <Typography className={classes.headerLine3}>
                                {props.place.address_components[3] &&
                                    props.place.address_components[3].long_name
                                }
                            </Typography>
                        </>
                    }
                </>}
        </div>
    );
}

function Content(props) {
    const classes = useStyles();

    return (
        <div className={classes.contentRoot}>
            <List dense>
                {
                    //TODO - Fix warning List generates for ReactDOMServer.renderToString
                    //List generates warning: Warning: useLayoutEffect does nothing on the server, because its effect cannot be encoded into the server renderer's output format.
                    props.layers.map(layer => {
                        return (
                            <ListItem className={classes.listItem} key={`infowindow-listitem-${layer.layerService}`}>
                                <ListItemText
                                    primary={layer.title}
                                />
                                <ListItemSecondaryAction >
                                    {
                                        availableCoverage(layer.layerCoverageName, props.coverage) ?
                                            <CheckCircleOutline  className={classes.hasCoverage}/>
                                            :
                                        <HighlightOff className={classes.noCoverage} />
                                    }
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })
                }
            </List>
        </div>
    );
}

function availableCoverage(layer, coverage) {
    if (!layer || !coverage || !coverage.success) return false;

    for(let i in coverage.services) {
        if (coverage.services[i].type === layer && coverage.services[i].providers.length > 0) {
            return true
        }
    };

    return false
}


export default function LayerListContent(props) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Header place={props.place} />
            <Divider />
            <Content layers={props.layers} coverage={props.coverage} />
        </div>
    );
}