import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import GoogleMap from '../components/GoogleMap';
import {BrandContext} from '../context/BrandContext/context';
import GoogleSearchBox from '../components/GoogleSearchBox';
import GoogleAutocompleteWidget from '../components/GoogleAutocompleteWidget';
import OverlayLayerSelector from '../components/OverlayLayerSelector';
import WMSOverlay from '../services/WMSOverlay'
import Utils from '../utils/Utils';
import Geolocation from '../services/Geolocation';
import LayerProviderListContent from '../components/MarkerContent/LayerProviderListContent';
import TowerContent from '../components/MarkerContent/TowerContent';
import {SiteContext} from '../context/SiteContext/context';
import fetchJsonp from 'fetch-jsonp';
import DrawLine from '../services/DrawLine';
import {Paper, TextField, Typography} from '@material-ui/core';
import {DataContext} from '../context/DataContext/context';
import LayerListContent from '../components/MarkerContent/LayerListContent';
import PoPContent from '../components/MarkerContent/PoPContent';
import CoverageMapContainer from './CoverageMapContainer';
import CoverageMapComponent from '../components/CoverageMapComponent';
import {head} from 'lodash';
import FeasibilityList from '../components/FeasibilityList';
import FileUploadComponent from "../components/FileUploadComponent";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import APIGateway from "../services/APIGateway";
// require('es6-promise').polyfill();

let GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow');
const ReactDOMServer = require('react-dom/server');

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    rootNav: {
        height: '100%',
        display: 'flex'
    },
    fileUpload: {
        width: '450px',
        minWidth: '350px',
        textAlign: "center",
        margin: '10px',
        padding: '10pt',
    },
    feasibilityList: {
        width: '100%',
        padding: '10pt',
        margin: '10px',
        textAlign: "center",
    },
    notes: {
        maxHeight: '70%',
        overflow: 'auto',
        marginBottom: '10pt',
        marginTop: '10pt',
    },
    paper: {
        padding: "10px",
        margin: '10px',
        textAlign: "center",
    }
}));

export default function BulkFeasibilityContainer(props) {
    const {brand} = useContext(BrandContext)
    const {settings} = useContext(SiteContext)
    const {data, dispatchData} = useContext(DataContext)
    const classes = useStyles()
    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
    });

    const [headers, setHeaders] = useState([
        'Site ID',
        'Network',
        'PoP',
        'LoS',
        'Latitude',
        'Longitude',
        'NRC',
        'MRC'
    ])
    const [columns, setColumns] = useState([
        'Site ID',
        'Network',
        'PoP',
        'LoS',
        'Latitude',
        'Longitude',
        'NRC',
        'MRC'
    ])
    const [items, setItems] = useState([])
    // const [feasibility, setFeasibility] = useState()
    const [loading, setLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [fileData, setFileData] = useState({})
    const [fileInfo, setFileInfo] = useState({})
    const [processing, setProcessing] = useState(false)
    const [canStart, setCanStart] = useState(false)
    const [uploading, setUploading] = useState(false)
    const [csvdata, setCSVData] = useState(false)
    const [formattedAddress, setFormattedAddress] = useState('')
    const [errorState, setErrorState] = useState('')

    const csvExportData = useState("siteid,latitude,longitude,term,bandwidth")

    const providerNames = {
        'wioccmetrolines': 'WIOCC Metro Lines',
        'wioccnationallines': 'WIOCC National Lines',
        'wioccpops': 'WIOCC PoPs'
    }

    useEffect(() => {
        // prepareColumns()
    }, [])

    const prepareColumns = () => {
        setLoading(true)
        if (!headers || head.length === 0) return;
        let newHeaders = headers
        let newColumns = columns
        if (!headers.includes('address')) {
            newHeaders.push('address')
            setHeaders(newHeaders)
        }

        newHeaders.forEach(header => {
            if (header.toLowerCase() !== "service" && header.toLowerCase() !== "slug" && header.toLowerCase() !== "name" && header !== 'address')
                newColumns.push({label: header, name: header.replace(/\s/g, '')});
            else if (header === 'address')
                newColumns.push({
                    label: header,
                    name: header.replace(/\s/g, ''),
                    options: {display: false, empty: true}
                });
        });
        setColumns(newColumns)
        setLoading(false)
    }


    const prepareItems = (feasibility) => {
        setLoading(true)
        console.log('feasibility!!!!!!!!!!!!!!!!!!!!', feasibility)
        let newItems = []

        setItems(correctItem(feasibility))
        setLoading(false)
    }

    const correctItem = (items) => {
        if (!items) return
        let newItems = []

        // items.foreEach(item => {
        //     newItems.push([
        //         item.siteid,
        //         item.latitude.toFixed(6),
        //         item.longitude.toFixed(6),
        //         mapProviderName(item),
        //         'PoP',
        //         'LoS',
        //         'Latitude',
        //         'Longitude',
        //     ])
        // })
        items.forEach(feasibilityItem => {
            let siteid = feasibilityItem.siteid;
            if (!feasibilityItem.success || feasibilityItem.services.length === 0) {
                newItems.push({
                    'Site ID': siteid,
                    'Network': 'Not Feasible',
                    'PoP': '',
                    'LoS': '',
                    'Latitude': '',
                    'Longitude': '',
                    'NRC': formatter.format(0),
                    'MRC': formatter.format(0)
                })
            } else {
                feasibilityItem.services.forEach((service) => {
                    service.providers.forEach((provider) => {
                        let item = {
                            'Site ID': siteid,
                        }
                        // item['Network'] = service.type
                        // var formatter = new Intl.NumberFormat('en-ZA', {
                        //     style: 'currency',
                        //     currency: 'ZAR',
                        // });
                        item['Network'] = mapProviderName(provider.provider)
                        if (provider.distance) item['LoS'] = provider.distance ? provider.distance.toFixed(2) + 'm' : ''
                        if (provider.pops) {
                            provider.pops.forEach(pop => {
                                let popItem = {
                                    'Site ID': siteid,
                                    'Network': item.Network,
                                    'PoP': pop.name,
                                    'LoS': pop.distance ? pop.distance.toFixed(2) + 'm' : "",
                                    'Latitude': pop.location.lat,
                                    'Longitude': pop.location.lng,
                                    'NRC': formatter.format(provider.nrc ? provider.nrc : 0),
                                    'MRC': formatter.format(provider.mrc ? provider.mrc : 0)
                                }
                                newItems.push(popItem)
                            })
                        } else {
                            item['NRC'] = formatter.format(provider.nrc ? provider.nrc : 0)
                            item['MRC'] = formatter.format(provider.mrc ? provider.mrc : 0)
                            newItems.push(item)
                        }

                    })
                })
            }
        })
        return newItems;
    }

    const mapProviderName = (provider) => {
        if (providerNames[provider]) return providerNames[provider]
        return provider
    }

    const onAddressChange = (place) => {
        if (!place) return
        if (place.formatted_address) setFormattedAddress(place.formatted_address)
        else setFormattedAddress(place)
    }

    const onResultsReceived = async (results) => {
        // prepareColumns(results)
        prepareItems(results)
    }

    const handleClick = (event) => {
        document.getElementById("hiddenFileInput").click();
    };

    const handleDownloadBulkTemplateClick = (event) => {
        document.getElementById("hiddenDownloadBulkTemplate").click();
    };

    const handleStart = async (event) => {
        if (canStart && csvdata) {
            setUploading(false)
            setProcessing(true)
            setCanStart(false)
            setLoading(true)
            let results = await APIGateway.getData(csvdata);
            console.log('results!!!!!!!!!!!!!!!!!!!!', results)
            console.log('results!!!!!!!!!!!!!!!!!!!!', ...results)
            prepareItems(results);
            setProcessing(false)
            setLoading(false)
            setFileInfo(undefined)
        }
    };

    const handleCancel = (event) => {
        setUploading(false)
        setProcessing(false)
        setCanStart(false)
        setLoading(false)
        setFileInfo(undefined)
    };

    const handleOnError = (err, file, inputElem, reason) => {
        console.log('Error in CSV Upload:', err);
        setErrorState(`CSV Upload Eror: ', ${err}`);
        // setUploading(false)
        setProcessing(false)
        setCanStart(false)
        setLoading(false)
        setFileInfo(undefined)
    };

    const handleOnFileLoad = async (csvdata, fileInfo) => {
        setUploading(true)
        setLoading(true)
        setErrorState('')
        setCSVData(undefined)
        setItems([])
        setFileInfo(fileInfo)

        if (!csvdata || csvdata.length === 0) {
            setErrorState('File Format Error: cannot upload an empty file.')
        } else if (
            csvdata[0].hasOwnProperty('siteid') &&
            csvdata[0].hasOwnProperty('longitude') &&
            csvdata[0].hasOwnProperty('latitude')&&
            csvdata[0].hasOwnProperty('bandwidth')&&
            csvdata[0].hasOwnProperty('term')
        ) {
            if (csvdata.length <= brand.maxBulkLocations ? brand.maxBulkLocations : 500) {
                setCanStart(true)
                setUploading(false)
                setLoading(false)
                setCSVData(csvdata)
            } else {
                setCanStart(false)
                setUploading(false)
                setLoading(false)
                setErrorState('File Amount Error: Please upload a maximum of 5000 locations.')
            }
        } else if (!csvdata[0].hasOwnProperty('siteid')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: siteid column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('longitude')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: longitude column not found in file.')
        } else if (!csvdata[0].hasOwnProperty('latitude')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: latitude column not found in file.')
        }else if (!csvdata[0].hasOwnProperty('bandwidth')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: bandwidth column not found in file.')
        }else if (!csvdata[0].hasOwnProperty('term')) {
            setCanStart(false)
            setUploading(false)
            setLoading(false)
            setErrorState('File Format Error: term column not found in file.')
        }

    };

    return (
        <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
            <Paper className={classes.fileUpload}>
                <FileUploadComponent
                    title={'Bulk Feasibility'}
                    downloadTemplate={'siteid,latitude,longitude,bandwidth,term'}
                    downloadTemplateHeading={'Download Bulk Template File'}
                    handleOnError={handleOnError}
                    handleOnFileLoad={handleOnFileLoad}
                    isDisabled={isDisabled}
                    processing={processing}
                    uploading={uploading}
                    canStart={canStart}
                    errorState={errorState}
                    handleStart={handleStart}
                    handleCancel={handleCancel}
                    fileInfo={fileInfo}>
                    <div className={classes.notes}>
                        <Box>
                            <Divider/>
                            <Typography
                                className={classes.title}
                                color="textSecondary"
                                variant="subtitle1"
                                gutterBottom
                            >
                                How to use the bulk feasibility
                            </Typography>
                            <Divider/>
                            {/* <Typography color="textSecondary" variant="caption"> */}
                            <List dense={true}>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Bulk feasibility provides the ability to check multiple sites
                                        or coordinates in batch format
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Started by downloading the CSV template here and populate the
                                        file with your site information, latitude, longitude,
                                        bandwidth, term
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Maximum number of coordinates is 5000 per batch
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Once bulk has started it cannot be stopped until the process
                                        has finished
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Make sure to use the template format as downloaded, do not
                                        change the headings
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Try to avoid using comma's (,) in the "siteid" field
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        We suggest you run separate files for bandwidth and term
                                        combinations
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        The results will be displayed on screen
                                    </Typography>
                                </ListItem>
                                <ListItem>
                                    <Typography color="textSecondary" variant="caption">
                                        Results can be downloaded after all the results has been
                                        processed
                                    </Typography>
                                </ListItem>
                                <ListItem
                                    component="a"
                                    href="https://youtu.be/ht6a-X3arkM"
                                    target="_blank"
                                >
                                    <Typography color="textSecondary" variant="caption">
                                        Watch the tutorial
                                    </Typography>
                                </ListItem>
                            </List>
                            {/* </Typography>  */}
                        </Box>
                    </div>
                </FileUploadComponent>
            </Paper>
            <Paper className={classes.feasibilityList}>
                <FeasibilityList
                    columns={columns}
                    data={items}
                    loading={loading}
                    formattedAddress={formattedAddress}
                />
            </Paper>
        </div>
    );
}
