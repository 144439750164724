import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BrandContext } from '../context/BrandContext/context';
import { SiteContext } from '../context/SiteContext/context';
import { DataContext } from '../context/DataContext/context';
import CoverageMapComponent from '../components/CoverageMapComponent';
import { head } from 'lodash';
import FeasibilityList from '../components/FeasibilityList';
import { Paper } from '@material-ui/core';

let GoogleDefaultInfoWindow = require('../utils/GoogleDefaultInfoWindow');
const ReactDOMServer = require('react-dom/server');

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
        // display:'flex'
    },
    rootNav: {
        height: '100%',
        // height: '88vh',
        // display:'flex'
    },
    coverageMap: {
        // height: '100%',
        height: '45%',
        width: '100%',
        position: 'relative'
        // width: '45%',
        // minWidth: '500px'
    },
    feasibilityList: {
        width: '100%',
        height: '55%',
        padding: '10pt',
        background: theme.palette.background
    }
}));

export default function SingleFeasibilityContainer(props) {
    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)
    const { data, dispatchData } = useContext(DataContext)
    const classes = useStyles()

    const [headers, setHeaders] = useState([
        'Network',
        'PoP',
        'LoS',
        'Latitude',
        'Longitude',
        'NRC',
        'MRC'
    ])
    const [columns, setColumns] = useState([
        'Network',
        'PoP',
        'LoS',
        'Latitude',
        'Longitude',
        'NRC',
        'MRC'
    ])
    const [items, setItems] = useState([])
    // const [feasibility, setFeasibility] = useState()
    const [loading, setLoading] = useState(false)
    const [formattedAddress, setFormattedAddress] = useState('')

    const providerNames = {
        'wioccmetrolines': 'WIOCC Metro Lines',
        'wioccnationallines': 'WIOCC National Lines',
        'wioccpops': 'WIOCC PoPs'
    }

    useEffect(() => {
        // prepareColumns()
    }, [])

    const prepareColumns = () => {
        setLoading(true)
        if (!headers || head.length === 0) return;
        let newHeaders = headers
        let newColumns = columns
        if (!headers.includes('address')) {
            newHeaders.push('address')
            setHeaders(newHeaders)
        }

        newHeaders.forEach(header => {
            if (header.toLowerCase() !== "service" && header.toLowerCase() !== "slug" && header.toLowerCase() !== "name" && header !== 'address')
                newColumns.push({ label: header, name: header.replace(/\s/g, '') });
            else if (header === 'address')
                newColumns.push({ label: header, name: header.replace(/\s/g, ''), options: { display: false, empty: true } });
        });
        setColumns(newColumns)
        setLoading(false)
    }

    const mapProviderName = (provider) => {
        if (providerNames[provider]) return providerNames[provider]
        return provider
    }

    const prepareItems = (feasibility) => {
        setLoading(true)
        let newItems = []
        if (!feasibility || !feasibility.success || !feasibility.services || feasibility.services.length === 0) {
            setLoading(false)
            setItems([])
            return
        }

        feasibility.services.forEach((service) => {
            service.providers.forEach((provider) => {
                let item = {}
                // item['Network'] = service.type
                item['Network'] = mapProviderName(provider.provider)
                if (provider.distance) item['LoS'] = provider.distance ? provider.distance.toFixed(2) + 'm' : ''
                var formatter = new Intl.NumberFormat('en-ZA', {
                    style: 'currency',
                    currency: 'ZAR',
                });
                if (provider.pops) {
                    provider.pops.forEach(pop => {
                        let popItem = {
                            'Network': item.Network,
                            'Provider': item.provider,
                            'PoP': pop.name,
                            'LoS': pop.distance ? pop.distance.toFixed(2) + 'm' : "",
                            'Latitude': pop.location.lat,
                            'Longitude': pop.location.lng,
                            'NRC': formatter.format(provider.nrc ? provider.nrc : 0),
                            'MRC': formatter.format(provider.mrc ? provider.mrc : 0)
                        }
                        newItems.push(popItem)
                    })
                } else {
                    item['NRC'] = formatter.format(provider.nrc ? provider.nrc : 0)
                    item['MRC'] = formatter.format(provider.mrc ? provider.mrc : 0)
                    newItems.push(item)
                }

            })
        })
        setItems(newItems)
        setLoading(false)
    }

    const onAddressChange = (place) => {
        if (!place) return
        if (place.formatted_address) setFormattedAddress(place.formatted_address)
        else setFormattedAddress(place)
    }

    const onResultsReceived = async (results) => {
        // prepareColumns(results)
        prepareItems(results)
    }

    return (
        <div className={brand.navbar.show && brand.fullHeight ? classes.rootNav : classes.root}>
            {/* <div className={classes.coverageMap}> */}
            <Paper className={classes.coverageMap}>
                <CoverageMapComponent
                    onAddressChange={onAddressChange}
                    onResultsReceived={onResultsReceived}
                    hidepopup={true}
                    hidelayercontrol={true}
                />
            </Paper>
            {/* </div> */}
            {/* <div className={classes.feasibilityList}> */}
            <Paper className={classes.feasibilityList}>
                <FeasibilityList
                    columns={columns}
                    data={items}
                    loading={loading}
                    formattedAddress={formattedAddress}
                />
            </Paper>
            {/* </div> */}
        </div>
    );
}
