import React, {useContext} from 'react'
import PropTypes from 'prop-types';

import {makeStyles, withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import SingleFeasibilityContainer from "./SingleFeasibilityContainer";
import BulkFeasibilityContainer from "./BulkFeasibilityContainer";
import CoverageMapComponent from "../components/CoverageMapComponent";
import FeasibilityList from "../components/FeasibilityList";
import {BrandContext} from "../context/BrandContext/context";
import {SiteContext} from "../context/SiteContext/context";


const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: '10pt',
        paddingRight: '10pt',
        flexGrow: 1,
    },
    component: {
        height: '88vh',
        width: '100%',
    },

}));

export default function FeasibilityContainer() {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const { brand } = useContext(BrandContext)
    const { settings } = useContext(SiteContext)

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root}>
            <AntTabs value={value} onChange={handleChange} aria-label="ant example" indicatorColor="primary">
                <AntTab label="Single Feasibility"/>
                <AntTab label="Bulk Feasibility"/>
            </AntTabs>
            <TabPanel value={value} index={0}>
                <div className={classes.component}>
                    <SingleFeasibilityContainer/>
                </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <div className={classes.component}>
                    <BulkFeasibilityContainer/>
                </div>
            </TabPanel>
        </div>
    );
}


const AntTabs = withStyles({
    root: {
        borderBottom: '1px solid #e8e8e8',
    },
    indicator: {
        backgroundColor: '#40a9ff',
    },
})(Tabs);


const AntTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(4),
        '&:hover': {
            color: theme.palette.primary.dark,
            opacity: 1,
        },
        '&$selected': {
            color: theme.palette.secondary.dark,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: theme.palette.secondary.dark,
        },
    },
    selected: {},
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <b>
                    {children}
                </b>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
