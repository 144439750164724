import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { BrandContext } from '../context/BrandContext/context';


export default function Legend(props) {


    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
            backgroundColor: theme.palette.background.paper,
        },
        statusLegend: {
            display: 'flex',
            backgroundColor: theme.palette.background.paper,
        },
        statusLegendItem: {
            display: 'flex',
            alignItems: 'center',
        },
        statusLegendText: {
            textAlign: 'center',
            width: '100%'
        },
        liveStatus: {
            display: 'flex',
            alignItems: 'center',
            padding: '2pt',
            backgroundColor: props.liveStatus && props.liveStatus.color ? props.liveStatus.color : theme.palette.live && theme.palette.live.main ? theme.palette.live.main : theme.palette.primary.main,
            color: props.liveStatus && props.liveStatus.contrastText ? props.liveStatus.contrastText : theme.palette.live && theme.palette.live.contrastText ? theme.palette.live.contrastText : theme.palette.primary.contrastText,
            width: props.hideComingSoon ? '100%' : '50%'
        },
        comingSoon: {
            display: 'flex',
            alignItems: 'center',
            padding: '2pt',
            backgroundColor: props.comingSoonStatus && props.comingSoonStatus.color ? props.comingSoonStatus.color : theme.palette.comingsoon && theme.palette.comingsoon.main ? theme.palette.comingsoon.main : theme.palette.secondary.main,
            color: props.comingSoonStatus && props.comingSoonStatus.contrastText ? props.comingSoonStatus.contrastText : theme.palette.comingsoon && theme.palette.comingsoon.contrastText ? theme.palette.comingsoon.contrastText : theme.palette.secondary.contrastText,
            width: props.hidelive ? '100%' : '50%',
            textAlign: 'center'
        },
    }));


    const classes = useStyles();
    const { brand } = useContext(BrandContext)

    const [checked, setChecked] = React.useState([0]);
    const [layerCollapseOpen, setLayerCollapseOpen] = React.useState(true);

    const handleLayerCollapseOpen = () => {
        setLayerCollapseOpen(!layerCollapseOpen);
    };

    const handleLayerDisplayToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (
        <div className={classes.root}>
            <div className={classes.statusLegend}>
                {!props.hideLive &&
                    <div className={classes.liveStatus}>
                        <Typography variant="caption" align='center' className={classes.statusLegendText}>
                            live
                    </Typography>
                </div>}
                {!props.hideComingSoon &&
                    <div className={classes.comingSoon}>
                        <Typography variant="caption" align='center' className={classes.statusLegendText}>
                            coming soon
                    </Typography>
                </div>}
            </div>
        </div>
    );
}
