import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { CheckCircleOutline, HighlightOff } from '@material-ui/icons';
import Utils from '../../utils/Utils';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '250px',
    },
    contentRoot: {
        width: '100%',
    },
}));

function Content(props) {
    const classes = useStyles();

    return (
        <div className={classes.contentRoot}>
            <Typography variant='h6'>
                {props.details && props.details.name ? props.details.name: 'No Tower Data to display'}
            </Typography>
            <Divider></Divider>
            <Typography variant='body1'>
                Distance: {props.details && props.details.distance ? props.details.distance : 0}m
            </Typography>
            <Divider></Divider>
        </div>
    );
}


export default function PoPContent(props) {
    const classes = useStyles();
    // let local1 = {
    //     latitude: props.location.latitude,
    //     longitude: props.location.longitude
    // }
    // let local2 = {
    //     latitude: props.details.location.lat,
    //     longitude: props.details.location.lng
    // }
    // const clientDegrees = Utils.angleFromCoords(local1.latitude, local2.latitude, local1.longitude, local2.longitude)
    // const degrees = Utils.angleFromCoords(local2.latitude, local1.latitude, local2.longitude, local1.longitude)
    return (
        <div className={classes.root}>
            <Content details={props.details} />
        </div>
    );
}