 import {
    Map, AddAlert, AllInclusive
} from "@material-ui/icons";
import CoverageMapContainer from "../containers/CoverageMapContainer";
import SingleFeasibilityContainer from "../containers/SingleFeasibilityContainer";
 import BulkFeasibilityContainer from "../containers/BulkFeasibilityContainer";
 import FeasibilityContainer from "../containers/FeasibilityContainer";


const Routes = [
    {
        path: "/",
        name: "Feasibility",
        icon: Map,
        component: FeasibilityContainer
    }, {
        path: "/single",
        name: "Single Feasibility",
        icon: Map,
        component: SingleFeasibilityContainer
    },{
        path: "/bulk",
        name: "Bulk Feasibility",
        icon: Map,
        component: BulkFeasibilityContainer
    },{
        path: "/map",
        name: "Coverage Map",
        icon: Map,
        component: CoverageMapContainer
    }
];

export default Routes;
