import React, {useContext, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import {BrandContext} from '../context/BrandContext/context';
import Utils from '../utils/Utils';
import MUIDataTable from 'mui-datatables';
import {Button, CircularProgress, Typography} from '@material-ui/core';
import {AssignmentReturned} from '@material-ui/icons';
import {CSVLink} from 'react-csv';
import CSVReader from "react-csv-reader";
// require('es6-promise').polyfill();

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
        width: '100%',
    },
    heading: {
        fontSize: "1.25rem",
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: "500",
        lineHeight: "1.6",
        letterSpacing: "0.0075em",
    },
    template: {},
    content: {
        overflow: 'auto',
        marginBottom: '10pt',
    },
    downloadBulkTemplate: {
        margin: '10px',
    },
    error: {
        marginTop: "5px",
        color: "#EE2624",
    },
    progress: {
        position: 'absolute',
        top: '50%',
        right: '12px',
        marginTop: -13,
    },
    loadingButtonWrapper: {
        // margin: theme.spacing(1),
        position: 'relative',
    },
    CSVReader: {
        display: "none"
    },
    processingButton: {
    },
    cancelButton: {
        marginLeft: '10px',
        marginRight: '10px',
        padding: '5pt'
    },
    canStartButton: {
        marginLeft: '10px',
        marginRight: '10px',
        padding: '5pt',
    },
    manageButtons: {
        display: 'flex'
    }
}));

export default function FileUploadComponent(props) {
    const {brand} = useContext(BrandContext)
    const classes = useStyles()
    const [randomKey, setRandomKey] = useState(Math.random().toString(36))

    const csvExportHeaders = [
        {label: "siteid", key: "siteid"},
        {label: "latitude", key: "latitude"},
        {label: "longitude", key: "longitude"},
        {label: "bandwidth", key: "bandwidth"},
        {label: "term", key: "term"},
    ]

    const csvExportData = "siteid,latitude,longitude,term,bandwidth"
    const [buttonText, setButtonText] = useState('')

    useEffect(() => {
        if (props.uploading) {
            setButtonText("Uploading")
        } else if (props.canStart) {
            setButtonText("Start Feasibility")
        } else if (props.processing) {
            setButtonText("Processing...")
        } else {
            setButtonText("Upload File")
        }
    }, [props.processing, props.canStart, props.uploading])

    const handleClick = (event) => {
        document.getElementById("hiddenFileInput").click();
    };

    const papaparseOptions = {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: "greedy",
        transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
        delimitersToGuess: ["\t", "|", ";"],
    };

    const handleDownloadBulkTemplateClick = (event) => {
        document.getElementById("hiddenDownloadBulkTemplate").click();
    };

    const handleOnFileLoad = (csvdata, fileInfo) => {
        setRandomKey(Math.random().toString(36))
        if(props.handleOnFileLoad) props.handleOnFileLoad(csvdata, fileInfo)
    }

    const handleCancel = (e) => {
        setRandomKey(Math.random().toString(36))
        if(props.handleCancel) props.handleCancel()
    }

    const handleOnError = (err, file, inputElem, reason) => {
        setRandomKey(Math.random().toString(36))
        if(props.handleOnError) props.handleOnError(err, file, inputElem, reason)
    }
    const handleStart = (e) => {
        setRandomKey(Math.random().toString(36))
        if(props.handleStart) props.handleStart(e)
    }

    return (
        <div className={classes.root}>
            <div className={classes.heading}>
                <span> {props.title}</span>
                {props.downloadTemplate &&
                <Button
                    variant="outlined"
                    id="templateButton"
                    aria-label="delete"
                    // classes={style.downloadIcon}
                    color="secondary"
                    size="small"
                    className={classes.templateButton}
                    onClick={handleDownloadBulkTemplateClick}
                    title={props.downloadTemplateHeading ? props.downloadTemplateHeading : "Download Bulk Template"}
                    startIcon={<AssignmentReturned/>}
                >
                    {
                        props.downloadTemplateHeading ? props.downloadTemplateHeading : 'Download Bulk Template File'
                    }
                    <CSVLink
                        id="hiddenDownloadBulkTemplate"
                        data={props.downloadTemplate}
                        filename={'bulk-template.csv'}
                        className={classes.downloadBulkTemplate}
                        enclosingCharacter={``}
                    > </CSVLink>
                </Button>
                }
            </div>
            <div className={classes.content}>
                {props.children}
            </div>
            <div className={classes.upload}>
                <div className={classes.loadingButtonWrapper}>
                    {props.canStart || props.processing ? (
                        <div className={classes.manageButtons}>
                            {!props.processing && (
                                <Button
                                    type="submit"
                                    // fullWidth
                                    size="small"
                                    variant="contained"
                                    className={classes.cancelButton}
                                    // disabled={processing}
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                size="small"
                                variant="contained"
                                color={'primary'}
                                className={
                                    props.processing ? classes.processingButton : classes.canStartButton
                                }
                                disabled={props.processing || props.uploading}
                                onClick={handleStart}
                            >
                                {buttonText}
                            </Button>
                        </div>
                    ) : (
                        <div>
                            {' '}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={props.processing || props.uploading}
                                onClick={handleClick}
                            >
                                {buttonText}
                            </Button>
                            {!props.canStart && !props.processing && (
                                <Typography align="center" variant="caption">
                                    Select a file to upload
                                </Typography>
                            )}
                        </div>
                    )}
                    {(props.processing) && (
                        <CircularProgress
                            size={24}
                            className={classes.progress}
                            color={'primary'}
                        />
                    )}
                </div>
                <div key={randomKey} className={classes.CSVReader}>
                    <CSVReader
                        cssClass="csv-reader-input"
                        label="Select CSV for bulk feasibility test"
                        onFileLoaded={handleOnFileLoad}
                        onError={handleOnError}
                        parserOptions={papaparseOptions}
                        inputId="hiddenFileInput"
                        inputStyle={{}}
                    />
                </div>
                <div className={classes.error}>{props.errorState}</div>
            </div><br/>
            {props.fileInfo && props.canStart &&
            (
                <Typography align="center" variant="caption">
                    {props.processing ? 'Processing' : 'Uploaded'} file: {props.fileInfo.name}
                </Typography>
            )}
        </div>
    );
}

