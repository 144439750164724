/* eslint-disable linebreak-style */
import axios from 'axios';

const WIOCCService = {};
// Expect data object:
// { lat, lng, apikey }

// USE /create path | returns jobid
// use if you didnt add locations to the create use addTo /locations
// use /start to start job
// use /getResults to get result (jobid, )

WIOCCService.doRequest = (data) => {
  let { siteid, latitude, longitude, bandwidth, term } = data;
  //need to have this as .env variable
  let apiKey = 'AIzaSyDKosoNTNsG4rK_2C69cemkg3D8tMccz0I';
  const url = 'https://east-wiocc.nw.r.appspot.com/getfeasibility';
  return axios
    .get(url, {
      params: {
        latitude,
        longitude,
        key: apiKey,
        channel: 'WIOCC Bulk Feasibility',
        bandwidth: bandwidth,
        term: term
      },
    })
    .then((response) => {
      // console.log('response', response);
      return WIOCCService.handleRequestSuccess(response, siteid);
    })
    .catch((error) => {
      // console.log('error', error);
      return WIOCCService.handleRequestError(error, siteid);
    });
};

WIOCCService.handleRequestSuccess = (response, siteid) => {
  // console.log('WIOCCService.handleRequestSuccessresponse: ', response);

  let responseData = response.data;
  let requestArray = [];

  requestArray.push({
    siteid: siteid,
    ...responseData
  });

  return  requestArray;
};

WIOCCService.handleRequestError = (error, siteid) => {
  return {
    siteid: siteid,
    success: false,
    error: `error: ${error}`,
    services: [],
  };
};

export default WIOCCService;
