import React, { createContext, useReducer } from "react";
import { reducer } from "./reducer";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [data, dispatchData] = useReducer(reducer, {
         layers: [
            {
                title: 'Business Fibre',
                layerName: 'businessfibre', //geoserver
                layerService: 'businessfibre', //service type
                layerCoverageName: 'businessfibre', //value to check for valid coverage, would change for multiple providers in the same service
                layerServiceName: 'Business Fibre',
                layerStyle: '',
                layerType: '',
                display: true,
                active: true,
                showLocation: false, //when not to display a layer but rather a point on the map
                hideLive: true,
                hideComingSoon: true,
                noLegend: true,
                noWMS: false
            },
            {
                title: 'POPs',
                layerName: 'pops',
                layerService: 'pops',
                layerServiceName: 'POPs',
                layerCoverageName: 'pops',
                layerStyle: 'blue',
                layerType: '',
                display: true,
                active: true,
                hideLive: true,
                hideComingSoon: false,
                noLegend: true,
                showLocation: true,
                // liveStatus: {
                //     color: '#fc4a2a',
                //     contrastText: '#ffffff'
                // },
                // hideComingSoon: true
                // comingSoonStatus: {
                //     color: '#6799fc',
                //     contrastText: '#ffffff'
                // }
            }
         ]
    });

    return (
        <DataContext.Provider value={{ data, dispatchData }}>
            {children}
        </DataContext.Provider>
    );
};

export default DataProvider;
