/* eslint-disable no-restricted-syntax */
/* eslint-disable linebreak-style */
/* eslint-disable no-trailing-spaces */

// import { map, of, Observable, Subscriber } from 'rxjs/operators';
import Bottleneck from 'bottleneck';

// import MicrowaveService from './APIs/MicrowaveService';
import CoverageService from './APIs/CoverageService';
import ClosestLineService from './APIs/ClosestLineService';
import LinkAfricaService from './APIs/LinkAfricaService';
import WIOCCService from "./APIs/WIOCCService";

const APIGateway = {};

const coverageLimiter = new Bottleneck({
    maxConcurrent: 40,
    minTime: 40,
});

const wioccThrottler = coverageLimiter.wrap(WIOCCService.doRequest);
// const linkAfricaThrottler = coverageLimiter.wrap(LinkAfricaService.doRequest);
// const linesThrottler = coverageLimiter.wrap(ClosestLineService.doRequest)
// const comsolThrottler = coverageLimiter.wrap(ComsolService.doRequest)
// const microwaveThrottler = coverageLimiter.wrap(MicrowaveService.doRequest)
// const popsThrottler = coverageLimiter.wrap(PopsService.doRequest)
// const seacomThrottler = coverageLimiter.wrap(SeacomService.doRequest)
// const frogfootThrottler = coverageLimiter.wrap(FrogfootService.doRequest)

coverageLimiter.on('failed', async (error, jobInfo) => {
    const id = jobInfo.options.id;
    console.warn(`Job ${id} failed: ${error}`);

    if (jobInfo.retryCount < 3) {
        // Here we only retry once
        console.log(`Retrying job ${id} in 50ms!`);
        return 50;
    }
});

coverageLimiter.on('retry', (error, jobInfo) =>
    console.log(`Now retrying ${jobInfo.options.id}`)
);


APIGateway.getFeasibility = async (locationData) => {
    if (locationData.length <= 0) return;

    let requestArray = [];
    const feasibilityArray = locationData.map((data) => wioccThrottler(data));

    requestArray.push(
        ...feasibilityArray
    );


    let results = await APIGateway.allProgress(requestArray, (progress) => {
        console.log(`Progress | ${progress.toFixed(2)}%`);
    }).then((values) => {
        console.log('promise.all() | values', values);
        return values;
    });

    return results;
};

APIGateway.processResponse = async (feasibilityData) => {

    console.log('[[[[[[[[[[[[[[[[[[APIGateway.processResponse]]]]]]]]]]]]]]]]]]');
    console.log(
        'APIGateway.processResponse = async(feasibilityData',
        feasibilityData
    );

    if (!feasibilityData) return []
    let responsesArray = [];


    for (const response of feasibilityData) {
        if (response) {
            if (Array.isArray(response)) {
                responsesArray = responsesArray.concat(response);
            } else {
                responsesArray.push(response);
            }
        }
    }

    console.log('APIGateway.processResponse responsesArray', responsesArray);
    console.log('[[[[[[[[[[[[[[[[[[]]]]]]]]]]]]]]]]]]');

    let servicesArray = []

    return responsesArray
};

APIGateway.verifyData = async (locationData) => {
    return locationData.reduce((result, data) => {
        let {siteid, latitude, longitude, term, bandwidth} = data;
        if (!siteid || siteid === '') {
            console.log(`Error at siteID: Invalid siteid or no siteid provided`);
            data.error = "Invalid siteid"
            data.success = false
            data.services = []
            result.push(data)
            return result;
        }
        if (
            latitude > 90 ||
            latitude < -90 ||
            longitude > 180 ||
            longitude < -180 ||
            latitude === '' ||
            longitude === '' ||
            !longitude ||
            !latitude
        ) {
            console.log(
                `Error at siteID:${siteid}: Invalid latitude or longitude. (lat: ${latitude}, lng: ${longitude})`
            );
            data.error = "Invalid latitude or longitude"
            data.success = false
            data.services = []
            result.push(data)
            return result;
        } else if (
            latitude.toString().includes(',') ||
            longitude.toString().includes(',')
        ) {
            const search = ',';
            const replaceWith = '.';

            let latResult = latitude.toString().replaceAll(search, replaceWith);

            let longResult = longitude.toString().replaceAll(search, replaceWith);

            data.latitude = latResult;
            data.longitude = longResult;
        }

        result.push(data);
        return result;
    }, []);
};

APIGateway.getData = async (locationData) => {

    if (!locationData) return;

    const verifiedData = await APIGateway.verifyData(locationData);

    const results = await APIGateway.getFeasibility(verifiedData).then((data) => {
        return data;
    });

    let processedResults = await APIGateway.processResponse(results);

    return processedResults;
};

APIGateway.allProgress = (promiseArray, progress_cb) => {
    let dataCount = 0;
    progress_cb(0);
    for (const currentPromise of promiseArray) {
        currentPromise.then(() => {
            dataCount++;
            progress_cb((dataCount * 100) / promiseArray.length);
        });
    }
    return Promise.all(promiseArray);
}

APIGateway.test = (ms) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            console.log(`Waited ${ms}`);
            resolve();
        }, ms);
    });
}

export default APIGateway;
